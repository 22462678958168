import root from 'window-or-global';

// https://gist.github.com/paullewis/55efe5d6f05434a96c36
const rICShim = (callback) => {
    const start = Date.now();
    return setTimeout(() => {
        callback({
            didTimeout: false,
            timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
        });
    }, 1);
};

const requestIdleCallback = root.requestIdleCallback || rICShim;

const loadAnalytics = () => {
    if (typeof window === 'undefined') {
        return;
    }

    if (process.env.HUGO_LOGROCKET) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const LogRocket = require('logrocket');
        // Specifing rootHostname enables tracking session accross subdomains (ie. from www to app).
        LogRocket.init(process.env.HUGO_LOGROCKET, {
            rootHostname: 'withhugo.com',
        });
    }

    if (process.env.HUGO_SEGMENT) {
        window.analytics.load &&
            window.analytics.load(process.env.HUGO_SEGMENT, {
                integrations: {
                    'Visual Tagger': false,
                },
            });
    }

    if (process.env.HUGO_ENV === 'production') {
        // https://intercom.help/mntn/en/articles/7251381-pixel-installation-guide-image-tag
        const cb = Math.floor(Math.random() * 1e17);
        const shaid = 41034;
        const img = new Image();
        img.src = `https://px.mountain.com/sti?shaid=${shaid}&cb=${cb}&plh=${encodeURIComponent(
            window.location.href,
        )}`;
        img.style.display = 'none';
        document.body.appendChild(img);
    }
};

export const runAnalytics = () => {
    requestIdleCallback(loadAnalytics, { timeout: 1000 });
};
